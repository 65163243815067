<template>
  <footer id="footer">
    <div class="row">
      <a href="https://twitter.com/FroggosTez" target="_blank">
        <img src="../assets/icons/twitter.svg" alt="" />
      </a>
      <p class="copyright">&copy; 2021-2022 Froggos</p>
      <a href="https://discord.gg/Q6x8aFjwUV" target="_blank">
        <img src="../assets/icons/discord.svg" alt="" />
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
footer {
  padding: 35px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #45616E;
  /* min-width: 100%; */
  .row {
    display: flex;
    align-items: center;
    .copyright {
      margin: 0 16px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 208%;
      text-align: center;
      color: #ffffff;
    }
  }
}
</style>
