<template>
  <div id="app">
    <Home />
  </div>
</template>

<script>
import Home from "./components/Home.vue";

export default {
  name: "App",
  components: {
    Home,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  padding: 0;
  background: #45616e;
  font-family: "Architects Daughter", cursive;
  color: #fff;
}
a, button {
  font-family: "Architects Daughter", cursive;
  color: #fff;
}
.primary-text {
  color: #409555;
}
</style>
