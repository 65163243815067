<template>
  <div id="faq-item-wrapper">
    <div class="row">
      <p class="question">
        {{ question }}
      </p>
      <button @click="toggleOpen" class="icon-wrapper">
        {{ open ? "-" : "+" }}
      </button>
    </div>

    <div>
      <p :class="`answer ${!open && 'close'}`" v-html="answer">
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqItem",
  data() {
    return {
      open: true,
    };
  },
  props: {
    question: String,
    answer: String,
  },
  methods: {
    toggleOpen() {
      this.open = !this.open;
    },
  },
};
</script>

<style lang="scss" scoped>
#faq-item-wrapper {
  padding-top: 30px;
  border-bottom: 3px solid #174154;
  p {
    margin: 0;
  }

  button {
    cursor: pointer;
    transition: 0.35s ease all;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .question {
    font-size: 24px;
  }

  .answer {
    transition: 0.35s ease all;
    font-size: 18px;
    margin-bottom: 30px;
    animation: slideInDown 1s ease 1 forwards;
  }

  .answer.close {
    animation: slideOutUp 1s ease 1 forwards;
    margin-bottom: 0px;
    display: none;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #30515f;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    padding: 15px;
    color: #fff;
    font-size: 28px;
  }
}

@keyframes slideInDown {
  from {
    display: block;
    max-height: 0;
  }

  to {
    max-height: 5000px;
  }
}

@keyframes slideOutUp {
  from {
    max-height: 5000px;
  }

  to {
    max-height: 0;
    display: none;
  }
}
</style>
