<template>
  <div style="min-width: 1440px">
    <div id="flex">
      <div style="position: relative">
        <img src="../assets/spaceship.png" alt="" id="home" />

        <h1 class="space-text" v-if="numMinted < maxMint">
          Mint <span class="primary-text">{{ progress }}</span> froggos <br />
          for <span class="primary-text">{{ progress * 10 }}</span> XTZ
        </h1>
        <h1 class="space-text" v-else style="width: 450px;"><span class="primary-text">SOLD OUT</span></h1>

        <span class="sync-account" v-if="wallet.connected">{{ `${wallet.pkh.substr(0, 6)}...${wallet.pkh.substr(-6)}` }}</span>

        <span class="minted-progress" v-if="numMinted < maxMint">{{numMinted}} / {{maxMint}} minted</span>

        <button class="sync-btn">
          <div class="top-shadow">
            <h1 v-if="checkWalletConnected() && wallet.connected === false" @click="connectWallet">SYNC</h1>
            <h1 v-else @click="disconnectWallet">UNSYNC</h1>
          </div>
          <div class="bottom-shadow"></div>
        </button>
        <div class="loader" v-if="numMinted < maxMint">
          <input value="1" min="1" max="10" step="1" v-on:input="setProgress" type="range" name="" id="slider" />
        </div>
        <button class="space-btn" v-if="numMinted < maxMint">
          <div class="top-shadow" @click="mint">
            <h1>MINT</h1>
          </div>
          <div class="bottom-shadow"></div>
        </button>
      </div>
    </div>

    <div id="faq">
      <div class="inner">
        <!-- <img src="../assets/faq/burst.svg" alt="" class="top-left" />
        <img src="../assets/faq/burst.svg" alt="" class="bottom-right" />
        <img src="../assets/faq/top-right.svg" alt="" class="top-right" />
        <img src="../assets/faq/bottom-left.svg" alt="" class="bottom-left" /> -->
        <div v-for="item in faqitems" v-bind:key="item.id">
          <FaqItem :question="item.question" :answer="item.answer" />
          <!-- {{item.question}} -->
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import FaqItem from "./FaqItem.vue";
import Footer from "./Footer.vue";
import { mapState, mapActions } from 'vuex'
import { getWalletContract } from '../utils/tezos';
import tzkt from '../utils/tzkt';

export default {
  components: { FaqItem, Footer },
  name: "Home",
  data() {
    return {
      progress: 1,
      mintOpPending: false,
      numMinted: 0,
      maxMint: 850,
      faqitems: [
        {
          id: "1",
          question: "What are Froggos?",
          answer:
            `Froggos is a generative NFT project created by <a href="https://twitter.com/ps1graphics2000" target="_blank">Lucas</a> and supported by 
            the <a href="https://crunchy.network/" target="_blank">Crunchy Network</a> team. This project was the culmination of over 5 months of 
            continuous design, development and community engagement. There are a total 2500 Froggos with 15 trait categories having 230 total unique trait.
            Each Froggos is diligently handcrafted and randomly generated and they all live on the Tezos blockchain.<br /><br />
            Feeling Froggy? Well, we have 11 super rares up for grabs!`,
        },
        {
          id: "2",
          question: "What is the price of a frog?",
          answer:
            "Mint price is set at 10 $XTZ",
        },
        {
          id: "3",
          question: "How do I get on the whitelist?",
          answer:
            "Please join our Discord to find details on how to enter and participate in the whitelisting process. Whitelist process closes on January 10, 2022 at 12:00 UTC",
        },
        {
          id: "4",
          question: "How will you ensure fair distribution?",
          answer:
            "The contract includes a provenance hash. This is a SHA-256 hash of an IPFS directory that refers to a fingerprint of the tokens and the art. It means anyone can verify that we didn’t change the tokens after minting them. Also, when the sale starts, the id of each mint is randomized.",
        },
        {
          id: "5",
          question: "Provenance & Contracts",
          answer: `Crowdsale Contract:<br />
            <a href="https://better-call.dev/mainnet/KT1TPreygRvZvHjRnPm9i4LsoMkzMLQvGZmN" target="_blank">KT1TPreygRvZvHjRnPm9i4LsoMkzMLQvGZmN</a><br /><br />
            Froggos NFT Contract<br />
            <a href="https://better-call.dev/mainnet/KT1QqTVamPvqEHMCKkzvYN8mxsxCCYjQKsdD" target="_blank">KT1QqTVamPvqEHMCKkzvYN8mxsxCCYjQKsdD</a><br /><br />
            Provenance Hash<br />
            a79c29b4c2d59f1a5a30239b4fa6efba7691289b2ed76f6d28b4fb0a3f320390`
        },
      ],
    };
  },
  computed: {
    ...mapState([
      'wallet'
    ])
  },
  created() {
    this.updateMinted();
  },
  methods: {
    ...mapActions([
      'checkWalletConnected',
      'connectWallet',
      'disconnectWallet',
      'changeWallet'
    ]),

    setProgress(evt) {
      this.progress = evt.target.value;
    },

    async updateMinted() {
      const storage = await tzkt.getContractStorage(process.env.VUE_APP_CROWDSALE_CONTRACT);
      this.numMinted = parseInt(storage.data.sale.soldAmount);
      if (this.numMinted < this.maxMint) {
        const vm = this;
        setTimeout(vm.updateMinted, 15000);
      }
    },

    async mint() {
      const amount = parseInt(this.progress * 10000000);
      if (amount < 1) {
          return;
      }

      try {
        await this.connectWallet();
        const op = await (await getWalletContract(process.env.VUE_APP_CROWDSALE_CONTRACT))
          .methods
          .mint(this.progress)
          .send({
            amount: amount,
            mutez: true,
          });
        this.mintOpPending = true;
        await op.confirmation();
      } catch (e) {
        console.log(e);
      }

      this.mintOpPending = false;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#flex {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #93ba9a;
  /* overflow: auto; */
  padding: 0;
  margin: 0;
}
#home {
  width: 1440px;
  position: relative;
  margin: 0;
  padding: 0;
  bottom: -6.2px;
}

button {
  position: absolute;
  border: 0;
  background: transparent;
  cursor: pointer;
  h1 {
    letter-spacing: 8px;
    color: #000;
    font-weight: 800;
  }

  .top-shadow {
    background-color: #409555;
    height: 45px;
    width: 180px;
    border: 5px solid #000;
    position: absolute;
    border-radius: 49%;
    z-index: 6;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%);
  }
  .bottom-shadow {
    clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%);
    width: 180px;
    background-color: #409555;
    height: 40px;
    border: 4px solid #000;
    position: absolute;
    border-radius: 49%;
    z-index: 0;
    top: 11px;
  }
}

.space-btn {
  bottom: 15%;
  left: 23%;
  transform: rotate(11deg);
}

.sync-btn {
  top: 2%;
  right: 13%;
}

.sync-account {
  position: absolute;
  top: 36%;
  left: 26%;
  transform: rotate(14deg);
  font-size: 24px;
  text-align: center;
  display: block;
  width: 400px;
}

.minted-progress {
  position: absolute;
  top: 69%;
  left: 20%;
  transform: rotate(12deg);
  font-size: 24px;
  text-align: center;
  display: block;
  width: 400px;
}

.loader {
  position: absolute;
  bottom: 21%;
  left: 18.5%;
  transform: rotate(12deg);
  display: flex;
  align-items: center;
}

#slider {
  -webkit-appearance: none;
  height: 8px;
  width: 400px;
  background: #817e86;
  border-radius: 5px;
  border: 3px solid #000;
}

#slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 4px solid #000;
  background: #409555;
  position: relative;
  z-index: 200;
}

.space-text {
  position: absolute;
  top: 43%;
  left: 21%;
  transform: rotate(12deg);
  font-size: 64px;
  text-align: center;
}

#faq {
  background: #45616e;
  display: flex;
  justify-content: center;
  .inner {
    padding: 120px 0px;
    box-sizing: border-box;
    width: 1440px;
    position: relative;
    @media all and (max-width: 1700px) {
      padding: 120px;
    }
    /* > img {
      width: 120px;
    }
    .top-left {
      position: absolute;
      left: 0px;
      top: 30px;
    }

    .top-right {
      position: absolute;
      right: 0px;
      top: 70px;
    }
    .bottom-left {
      position: absolute;
      left: 0px;
      bottom: 70px;
    }
    .bottom-right {
      position: absolute;
      right: 0px;
      bottom: 30px;
    } */
  }
}
</style>
